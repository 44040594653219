import { Deserializable } from '../../interfaces/deserializable';

export class HeaderModel implements Deserializable {
	public receivedTime: Date = null;
	public sentTime: Date = null;
	public subject: string = null;
	public from: string = null;
	public to: string = null;
	public deliveredTo: string = null;
	public separateHeader: boolean = null;
		
	deserialize(input: any): this {
		Object.getOwnPropertyNames(this).forEach(property => {
			if (input[property] != null && input[property] != undefined) {
				this.assignProperty(property, input[property]);
			}
		});
		return this;
	}

	assignProperty(propertyName: string, inputValue: any): void {
		switch (propertyName) {
			case 'receivedTime':
			case 'sentTime':
				this[propertyName] = new Date(inputValue);
				break;
			default:
				this[propertyName] = inputValue;
				break;
		}
	}

}
