import { Deserializable } from '../interfaces/deserializable';
import { OcrModel } from './ocr/ocr.model';
import { MailInfoModel } from './mail/mail-info.model';
import { FileModel } from './file/file.model';
import { ImageModel } from './image/image.model';
import { IdDataModel } from './id-data/idData.model';
import { TagModel } from './tag/tag.model';
import { ProductionPdfModel } from './production/production-pdf.model';

export class DocumentDataModel implements Deserializable {
	public batchID: string = null;
	public batchHash: string = null;
	public folderID: string = null;
	public folderHash: string = null;
	public parentFolderHash: string = null;
	public status: string = null;
	public project: string = null;
	public area: string = null;
	public source: string = null;
	public scannedDate: Date = null;
	public userName: string;
	public sla: Date = null;
	public modelName: string = null;
	public productionPath: string = null;
	public remarks: Array<string> = null;
	public mailInfo: MailInfoModel = null;
	public ocr: Array<OcrModel> = null;
	public fileList: Array<FileModel> = null;
	public imagesList: Array<ImageModel> = null;
	public folderIdentification: Array<IdDataModel> = null;
	public folderClassification: Array<IdDataModel> = null;
	public tagsHistory: Array< Array<TagModel> > = null;
	public productionInfoHistory: Array<Array<ProductionPdfModel> > = null;
	public customerReview: number = null;

	private unparsedData;

	deserialize(input: any): this {
		Object.getOwnPropertyNames(this).forEach(property => {
			if (input[property] != null && input[property] != undefined) {
				this.assignProperty(property, input[property]);
			}
		});
		return this;
	}

	setUnparsedData(input: any){
		this.unparsedData = input;
	}

	assignProperty(propertyName: string, inputValue: any): void {
		switch (propertyName) {
			case 'scannedDate':
			case 'sla':
				this[propertyName] = new Date(inputValue);
				break;
			case 'ocr':
				this.ocr = new Array<OcrModel>();
				for (var value in inputValue) {
					this.ocr.push(new OcrModel().deserialize({ imageHash: value, ocrValue: inputValue[value] }));
				}
				break;
			case 'mailInfo': 
				this.mailInfo = new MailInfoModel().deserialize(inputValue);
				break;
			case 'fileList': 
				this.fileList = new Array<FileModel>();
				for (var value in inputValue) {
					this.fileList.push(new FileModel().deserialize(inputValue[value]));
				}
				break;
			case 'imagesList': 
				this.imagesList = new Array<ImageModel>();
				for (var value in inputValue) {
					this.imagesList.push(new ImageModel().deserialize(inputValue[value]));
				}
				break;
			case 'folderIdentification': 
				this.folderIdentification = new Array<IdDataModel>();
				for (var value in inputValue) {
					this.folderIdentification.push(new IdDataModel().deserialize(inputValue[value]));
				}
				break;
			case 'folderClassification':
				this.folderClassification = new Array<IdDataModel>();
				for (var value in inputValue) {
					this.folderClassification.push(new IdDataModel().deserialize(inputValue[value]));
				}
				break;
			case 'tagsHistory': 
				this.tagsHistory = new Array<Array<TagModel> >();
				for (var value in inputValue) {
					var tags: Array<TagModel> = new Array<TagModel>();
					for (var tag in inputValue[value]) {
						tags.push(new TagModel().deserialize(inputValue[value][tag]));
					}
					this.tagsHistory.push(tags);
				}
				break;
			case 'productionInfoHistory': 
				this.productionInfoHistory = new Array<Array<ProductionPdfModel>>();
				for (var value in inputValue) {
					var pdfs: Array<ProductionPdfModel> = new Array<ProductionPdfModel>();
					for (var pdf in inputValue[value]) {
						pdfs.push(new ProductionPdfModel().deserialize(inputValue[value][pdf]));
					}
					this.productionInfoHistory.push(pdfs);
				}
				break;
			default:
				this[propertyName] = inputValue;
				break;
		}
	}

	getJson(): JSON {
		return (JSON.parse(this.parseToJsonFormat()));
	}

	parseToJsonFormat(): string {
		return JSON.stringify(this);
	}

	getOcrMap(): Map<string, string> {
		var ocrMap: Map<string, string> = new Map<string, string>();
		this.ocr.forEach(data => {
			ocrMap.set(data.imageHash, data.ocrValue);
		});
		return ocrMap;
	}

	getImagesHashes(): Array<string> {
		var hashes: Array<string> = new Array<string>();
		this.imagesList.forEach(image => hashes.push(image.imageHash + "&deleted:" + image.deleted + "&pdf:false"));
		return hashes;
	}
}
