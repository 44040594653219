import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { DocumentDataModel } from '../models/document-data.model';

@Injectable({
  providedIn: 'root',
})
export class ProductionViewService {
  private documentData = null;
  private resize = new Subject<void>();
  private controllerAPI: string;
  private project: string;

  constructor(private http: HttpClient) {}

  getDocumentProject(folderHash: string): Observable<any> {
    var url = `${environment.portalURL}/api/Project/GetProjectName?folderHash=${folderHash}`;

    return this.http
      .get(url)
      .pipe(tap((data: string) => (this.project = data.toLowerCase())));
  }

  getDocumentData(folderHash: string): Observable<DocumentDataModel> {
    this.controllerAPI = environment.controllerAPI.replace(
      'PROJECTNAME',
      this.project
    );
    var url = this.controllerAPI + '/api/Document?hash=' + folderHash;

    return this.http.get(url).pipe(
      map((data) => {
        this.documentData = new DocumentDataModel().deserialize(data);
        this.documentData.setUnparsedData(data);
        return this.documentData;
      })
    );
  }

  async unlockEntry(folderHash): Promise<any> {
    const url: string =
      environment.portalURL +
      '/api/ManualProcess/UnlockEntry?folderHash=' +
      folderHash;

    return await this.http
      .post(url, '', { observe: 'response' })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('ERROR Unlocking Entry [MercedesService]\n', error);
        return error;
      });
  }
}
