import { Deserializable } from '../../interfaces/deserializable';

export class IdDataModel implements Deserializable {

	public ids: Array<string> = null;
	public username: string = null;
	public dateTime: Date = null;

	deserialize(input: any): this {
		Object.getOwnPropertyNames(this).forEach(property => {
			if (input[property] != null && input[property] != undefined) {
				this.assignProperty(property, input[property]);
			}
		});
		return this;
	}

	assignProperty(propertyName: string, inputValue: any): void {
		switch (propertyName) {
			case 'dateTime':
				this[propertyName] = new Date(inputValue);
				break;
			case 'ids': 
				this.ids = new Array<string>();
				for (var value in inputValue) {
					this.ids.push(inputValue[value]);
				}
				break;
			default:
				this[propertyName] = inputValue;
				break;
		}
	}
}
