import { Deserializable } from '../../interfaces/deserializable';
import { Options } from './options.enum';
import { HeaderModel } from './header.model';

export class MailInfoModel implements Deserializable {
	public globalIdMail: string = null;
	public googlelIdMail: string = null;
	public idDrive: string = null;
	public startTime: Date = null;
	public endTime: Date = null;
	public projectName: string = null;
	public modelName: string = null;
	public mailSize: number = null;
	public boxPrefix: string = null;
	public options: Array<Options> = null;
	public bodyHash: string = null;
	public header: HeaderModel = null;
	public label: string = null;
	public labelId: string = null;
	public labelIdProcessed: string = null;
	public priority: number = null;
	public inlineMode: number = null;
	public maxInlineSizeKB: number = null;
	public bodyImages: Array<string> = null;
	public addressEmail: string = null;
	public rawBody: string = null;

	deserialize(input: any): this {
		Object.getOwnPropertyNames(this).forEach(property => {
			if (input[property] != null && input[property] != undefined) {
				this.assignProperty(property, input[property]);
			}
		});
		return this;
	}

	assignProperty(propertyName: string, inputValue: any): void {
		switch (propertyName) {
			case 'startTime':
			case 'endTime':
				this[propertyName] = new Date(inputValue);
				break;
			case 'header':
				this.header = new HeaderModel().deserialize(inputValue);
				break;
			default:
				this[propertyName] = inputValue;
				break;
		}
	}
}
