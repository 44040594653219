import { Deserializable } from '../../interfaces/deserializable';
import { ProductionFilesModel } from './production-files-model';

export class ProductionPdfModel implements Deserializable {

	public filename: string = null;
	public files: Array<ProductionFilesModel>  = null;
	public tags: Array<number> = null;

	deserialize(input: any): this {
		Object.getOwnPropertyNames(this).forEach(property => {
			if (input[property] != null && input[property] != undefined) {
				this.assignProperty(property, input[property]);
			}
		});
		return this;
	}

	assignProperty(propertyName: string, inputValue: any): void {
		switch (propertyName) {
			case 'files':
				this.files = new Array<ProductionFilesModel>();
				for (var value in inputValue) {
					//TODO update when variables names on ProductionFileModel change
					this.files.push(new ProductionFilesModel().deserialize({ list: value, name: inputValue[value] }));
				}
				break;
			default:
				this[propertyName] = inputValue;
				break;
		}
	}
}
