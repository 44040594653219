import { Deserializable } from '../../interfaces/deserializable';
import { IdDataModel } from '../id-data/idData.model';

export class TagModel implements Deserializable {
	
	public process: string = null;
	public user: string = null;
	public confidenceLevel: number = null;
	public acceptedThreshold: number = null;
	public classificationLanguage: string = null;
	public threshold: number = null;
	public images: Array<string> = null;
	public labels: Array<string> = null;
	public identification: Array<IdDataModel> = null;
	public status: string = null;
	public typeDataEntry: string = null;
	public assemblyQualifiedName: string = null;
	public dataEntries: any[] = null;

	deserialize(input: any): this {
		Object.getOwnPropertyNames(this).forEach(property => {
			if (input[property] != null && input[property] != undefined) {
				this.assignProperty(property, input[property]);
			}
		});
		return this;
	}

	assignProperty(propertyName: string, inputValue: any): void {
		switch (propertyName) {
			case 'identification':
				this.identification = new Array<IdDataModel>();
				for (var value in inputValue) {
					this.identification.push(new IdDataModel().deserialize(inputValue[value]));
				}
				break;
			default:
				this[propertyName] = inputValue;
				break;
		}
	}

	
}
