import { Deserializable } from '../../interfaces/deserializable';

export class ImageModel implements Deserializable {

	public imageHash: string = null;
	public deleted: boolean = null;

	deserialize(input: any): this {
		Object.getOwnPropertyNames(this).forEach(property => {
			if (input[property] != null && input[property] != undefined) {
				this[property] = input[property];
			}
		});
		return this;
	}
}