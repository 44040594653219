import { Deserializable } from '../../interfaces/deserializable';

export class ProductionFilesModel implements Deserializable {

	public name: string = null;    //TODO get valid variable's name
	public list: Array<string> = null; //TODO get valid variable's name

	deserialize(input: any): this {
		Object.getOwnPropertyNames(this).forEach(property => {
			if (input[property] != null && input[property] != undefined) {
				this[property] = input[property];
			}
		});
		return this;
	}
}